<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Proyectos</h4>
            <div class="small text-muted">Panel de control de toda la gestión de proyectos</div>
          </b-col>
          <b-col>            
            <b-icon icon="question-circle-fill" class="h3 pull-right mt-2 module-help-information" v-b-popover.hover="help"></b-icon>            
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">  
          <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">

            <b-tab title="General" :active="tabs.main" @click="setActiveTab('main')">
              <b-row>                                
                <b-col md="4">  
                  <Widget :configWidget="configWidget" reference="pie_activity_project" :hasAccess="configWidget.elements.pieActivityProjects" />
                </b-col>                                    
                <b-col md="4">                    
                  <Widget :configWidget="configWidget" reference="pie_detail_project" :hasAccess="configWidget.elements.pieDetailProjects" />
                  <Widget :configWidget="configWidget" reference="pie_detail_activity" :hasAccess="configWidget.elements.pieDetailActivity" />
                </b-col>                                 
                <b-col md="4">                
                  <Widget :configWidget="configWidget" reference="list_days_incomplete_hours" :hasAccess="configWidget.elements.listDaysIncompleteHours" />
                </b-col>                                        
              </b-row>

            </b-tab>
            <b-tab title="Proyectos con Vencimiento" :active="tabs.with_expiration" @click="setActiveTab('with_expiration')">
              <b-row>
                <b-col md="3">  
                  <Widget :configWidget="configWidget" reference="pie_projects_status" :hasAccess="configWidget.elements.pieProjectsStatus" />              
                </b-col>            
                <b-col md="4">  
                  <Widget :configWidget="configWidget" reference="list_projects_vencimientos_treinta_dias" :hasAccess="configWidget.elements.listProjectsVto" />              
                </b-col>
                <b-col md="5">  
                  <Widget :configWidget="configWidget" reference="list_hours_estimated_vs_hurs_real" :hasAccess="configWidget.elements.listProjectsEstimatedVsReal" />
                </b-col> 
              </b-row>
            </b-tab>
            <b-tab title="Proyectos sin Vencimiento" :active="tabs.without_expiration" @click="setActiveTab('without_expiration')">
              <b-row>
                <b-col md="3">  
                  <Widget :configWidget="configWidget" reference="pie_projects_status_without_expiration" :hasAccess="configWidget.elements.pieProjectsStatusWithoutExpiration" />              
                </b-col> 
                <b-col md="6">  
                  <Widget :configWidget="configWidget" reference="list_hours_real_project_without_expiration" :hasAccess="configWidget.elements.listHoursRealProjectWithoutExpiration" />
                </b-col>                            
              </b-row>              
            </b-tab>
          </b-tabs>                                
        </b-col>
        <b-col md="3">
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openProjects()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Proyectos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de proyectos
              </p>
            </b-list-group-item>
          </b-list-group>          

          <b-list-group v-if="!isEmployee || getEmployeePermission">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openActivity()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Actividad</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de actividades
              </p>
            </b-list-group-item>
          </b-list-group>      

          <b-list-group v-if="isEmployee || getEmployeePermission">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openHours()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Horas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Gestión de horas consumidas
              </p>
            </b-list-group-item>
          </b-list-group>  

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>          
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Storage from '@/handler/storageLocal'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PROYECTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.PROYECTOS,
          profile_id: Profiles.PERSONAL,
          elements: {
            pieProjectsStatus: true,
            pieProjectsStatusWithoutExpiration: true,            
            listProjectsVto: true,
            listProjectsEstimatedVsReal: true,
            listHoursRealProjectWithoutExpiration: true,
            listDaysIncompleteHours: true,
            pieActivityProjects: true,
            pieDetailActivity: true,
            pieDetailProjects: true,
          }
        },
        primaryColor: '',
        arr: {
          PHPCustom: []
        },        
        help: {
          title:'¿Cómo funciona este módulo?',
          content: () => { 
            return `Un usuario con perfil <b>STAFF</b>, es el administrador del módulo, 
                    el cual puede visualizar toda la información de forma generalizada. Podrá crear 
                    nuevos proyectos y armar equipos de trabajo.
                    <br><br>
                    Un usuario con perfil <b>STAFF</b> y configurado como <b>EMPLEADO</b>, puede ser asignado como
                    responsable de un proyecto o estar incluido dentro de un equipo de trabajo. Este usuario 
                    podrá cargar las horas de trabajo.`
          },            
          html: true
        },
        tabs: {
          main: true,
          with_expiration: false,
          without_expiration: false,
        }        
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */
      this.configWidget.elements.pieProjectsStatus = Helper.hasAccessWidget(this.configWidget, 'pie_projects_status')
      this.configWidget.elements.pieProjectsStatusWithoutExpiration = Helper.hasAccessWidget(this.configWidget, 'pie_projects_status_without_expiration')
      this.configWidget.elements.listProjectsVto = Helper.hasAccessWidget(this.configWidget, 'list_projects_vencimientos_treinta_dias')
      this.configWidget.elements.listProjectsEstimatedVsReal = Helper.hasAccessWidget(this.configWidget, 'list_hours_estimated_vs_hurs_real')
      this.configWidget.elements.listHoursRealProjectWithoutExpiration = Helper.hasAccessWidget(this.configWidget, 'list_hours_real_project_without_expiration')
      this.configWidget.elements.pieActivityProjects = Helper.hasAccessWidget(this.configWidget, 'pie_activity_project')
      this.configWidget.elements.pieDetailActivity = Helper.hasAccessWidget(this.configWidget, 'pie_detail_activity')
      this.configWidget.elements.pieDetailProjects = Helper.hasAccessWidget(this.configWidget, 'pie_detail_project')
      this.configWidget.elements.listDaysIncompleteHours = Helper.hasAccessWidget(this.configWidget, 'list_days_incomplete_hours')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
      this.getPHPCustom()

      this.restoreStatusTabsStorage()
    }, 
    computed: {
      isEmployee(){
        if(Helper.getEmployee()) {
          return true
        } else {
          return false
        }
      },
      getEmployeePermission() {
        if(this.isEmployee) {
          return Helper.getEmployee().permission_admin_projects
        } else {
          return false
        }
      }
    },    
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openProjects() {
        this.$router.push({ name: 'ProjectStaffCrudProject' })
      },    
      openActivity() {
        this.$router.push({ name: 'ProjectStaffCrudActivity' })
      },    
      openHours() {
        this.$router.push({ name: 'ProjectStaffCrudHours' })
      },  
      
      //TABS
      disableAllTabs() {
        this.tabs.main = false
        this.tabs.with_expiration = false
        this.tabs.without_expiration = false
      },
      setActiveTab(value) {
        this.disableAllTabs()

        switch (value) {
          case 'main':
            this.tabs.main = true
            break;

          case 'with_expiration':
            this.tabs.with_expiration = true
            break;
        
          case 'without_expiration':
            this.tabs.without_expiration = true
            break;

          default:
            this.tabs.main = true
            break;
        }

        this.saveStatusTabsStorage()
      },  

      // FILTROS SAVE
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_dashboard_projects', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_dashboard_projects')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_dashboard_projects'))
        }         
      },  

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.PROYECTOS)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>